import React from 'react'
import Work from '../../assets/27ADDA4E-0022-406D-A7FA-F7C0A05D5905.JPG'
import Work640 from '../../assets/27ADDA4E-0022-406D-A7FA-F7C0A05D5905.JPG'
import Work768 from '../../assets/27ADDA4E-0022-406D-A7FA-F7C0A05D5905.JPG'
import Work1024 from '../../assets/27ADDA4E-0022-406D-A7FA-F7C0A05D5905.JPG'

const Service = () => {
  return (
    <div className="flex items-center justify-center">
    <div className='flex flex-col md:grid grid-cols-2'>
        <div className="">
          <picture>
            <source srcSet={Work640} media="(max-width: 640px)" />
            <source srcSet={Work768} media="(max-width: 768px)" />
            <source srcSet={Work1024} media="(max-width: 1024px)" />
            <img src={Work} alt="2 killar som jobbar" />
            </picture>
        </div>
        <div className="flex flex-col py-4 px-4 md:px-16 md:pl-24 justify-center bg-[#0F0E20]">
            <h4 className='uppercase font-bold text-[#4EF4FF] text-3xl'>Vår service kan du lita på</h4>
            <p>Tillsammans med våra kunder löser vi ditt behov för betongpumpning med bästa service och resultat. Vi har alltid kunden i fokus och ett utarbetat servicetänk. Vi har stor erfarenhet inom betongbranchen och har förståelse för alla delar av processen. Kontakta oss för en offert eller att planera ditt projekt.</p>
        </div>
    </div>
    </div>
  )
}

export default Service