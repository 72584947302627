import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import Logo from './assets/CSClogotyp 1.svg'
// import Logo from '../assets/'
// import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'

const Navbar = () => {
    // Makes the state true if set to false on click and false if it's set to true 
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

  return (
    <div className='fixed z-10 shadow-md w-full h-[80px] flex justify-center items-center text-white bg-[#18173B]'>
        <div className="max-w-[1200px] lg:px-0 px-4 w-full flex items-center justify-between">
        <div>
            <a href="/">
            <img src={Logo} alt="Logo image" className='h-auto' style={{ width: '70px' }} />
            </a>
        </div>

        
            <ul className='hidden lg:flex desktop items-center text-white font-bold'>
                <li className='px-4'>
                    <a href="/">Hem</a>
                </li>
                <span className='text-2xl px-6'>/</span>
                <li className='px-4'>
                     <a href="/omoss">
                    Om oss
                    </a>
                </li>
                <span className='text-2xl px-6'>/</span>
                <li className='px-4'>
                    <a href="/tjanster">
                    Våra tjänster
                    </a>
                </li>
                <span className='text-2xl px-6'>/</span>
                <li className='px-4'>
                    <a href="/jobbahososs">
                    Jobba hos oss
                    </a>
                </li>
            </ul>

            
        
        {/* Hamburger */}
        <div onClick={handleClick} className='lg:hidden z-30 cursor-pointer text-black'>
            {!nav ? <FaBars /> : <FaTimes />} 
        </div>

        {/* Mobile menu */}
        
            <ul className={!nav ? 'hidden' : 'absolute z-20 mobile cursor-pointer text-lg top-0 left-0 w-full text-white h-screen bg-[#242349] flex flex-col items-left pl-4 pt-8'}>
                <div className="pb-8">
                    <a href="/">
                        <img className='w-16' src={Logo} alt="Logotyp" />
                    </a>
                </div>
                <li className='py-6'>
                    <a href="/">Hem</a>
                </li>
                <li className='py-6'>
                     <a href="/omoss">
                    Om oss
                    </a>
                    </li>
                <li className='py-6'>
                   <a href="/tjanster">
                    Våra tjänster
                    </a>
                   </li>
                <li className='py-6'>
                    <a href="/jobbahososs">
                    Jobba hos oss
                    </a>
                   </li>
                   <div className="flex items-center justify-center">
                   <a href="/kontakt">
            <button className='font-bold py-2 px-6 w-full uppercase bg-white text-black'>Kontakta oss</button>
            </a>
            </div>
            </ul>
                <a className='hidden lg:block' href="/kontakt">
            <button className='font-bold hover:bg-[#4EF4FF] duration-200 py-2 px-6 uppercase bg-white text-black'>Kontakta oss</button>
            </a>
    </div>
    </div>
  )
}

export default Navbar