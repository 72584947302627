import React from 'react'
import PictureUp from '../../assets/277673464_3412624322298524_5055426983703188087_n.jpg'
import PictureUp640 from '../../assets/277673464_3412624322298524_5055426983703188087_n.jpg'
import PictureUp768 from '../../assets/277673464_3412624322298524_5055426983703188087_n.jpg'
import PictureUp1024 from '../../assets/277673464_3412624322298524_5055426983703188087_n.jpg'
import PictureWork from '../../assets/20210423_133045-01.jpg'
import PictureWork640 from '../../assets/20210423_133045-01-640.jpg'
import PictureWork768 from '../../assets/20210423_133045-01-768.jpg'
import PictureWork1024 from '../../assets/20210423_133045-01-1024.jpg'
import PictureWork2 from '../../assets/20201125_083407-01.jpg'
import PictureWork2640 from '../../assets/20201125_083407-01-640.jpg'
import PictureWork2768 from '../../assets/20201125_083407-01-768.jpg'
import PictureWork21024 from '../../assets/20201125_083407-01-1024.jpg'
import PictureWork3 from '../../assets/20210624_112026.jpg'
import PictureWork3640 from '../../assets/20210624_112026-640.jpg'
import PictureWork3768 from '../../assets/20210624_112026-768.jpg'
import PictureWork31024 from '../../assets/20210624_112026-1024.jpg'
import LogoSVG from '../../assets/CSClogotyp 3.svg'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'



const Masonry = () => {
  return (
    <div>
        <div className="grid-custom mt-20 grid gap-4 w-full">
        <div className="md:grid grid-cols-3 gap-4 ">
            <picture>
            <source srcSet={PictureUp640} media="(max-width: 640px)" />
            <source srcSet={PictureUp768} media="(max-width: 768px)" />
            <source srcSet={PictureUp1024} media="(max-width: 1024px)" />
            <img src={PictureUp} className="w-full object-cover h-96 xl:h-[260px]" alt="Cementbil" />
            </picture>
            <div className="flex flex-col relative h-fit xl:h-full px-4 py-4">
                <h3 className='font-bold text-3xl py-4'>
                    Vi pumpar betong!
                </h3>
                <p className='pb-2'>Vi erbjuder betongpumps-jobb vid konstruktioner åt både företag och privatpersoner. Vi använder oss utav pumpningsbar betong som levereras av en betongpumpbil. Kontakta oss för ett kostnadsförslag & prislista.</p>
                <div className="flex items-center flex-row justify-end">
                <a className='group' href="/tjanster">
                    <div className="flex items-center justify-center">
                    <p className='uppercase px-4 font-black'>Våra tjänster</p>
                    <button aria-label='Contact' className='mr-4 rounded-full w-10 h-10 flex items-center justify-center'><BsFillArrowRightCircleFill fill="#4EF4FF" className='group-hover:fill-white duration-200 w-10 h-10'/></button>
                    </div>
                </a>
                </div>
            </div>
            <picture>
            <source srcSet={PictureWork640} media="(max-width: 640px)" />
            <source srcSet={PictureWork768} media="(max-width: 768px)" />
            <source srcSet={PictureWork1024} media="(max-width: 1024px)" />
            <img className='xl:h-[260px] h-96 w-full object-cover object-left' src={PictureWork} alt="Carlstad Concrete Solution" />
            </picture>
        </div>  
        <div className="grid grid-cols-5 gap-4">
            <div className="col-span-3">
            <picture>
            <source srcSet={PictureWork2640} media="(max-width: 640px)" />
            <source srcSet={PictureWork2768} media="(max-width: 768px)" />
            <source srcSet={PictureWork21024} media="(max-width: 1024px)" />
            <img className='w-full h-[260px] object-cover object-left' src={PictureWork2} alt="Work" />
            </picture>
            </div>
            <div className="col-span-2">
           <picture>
            <source srcSet={PictureWork3640} media="(max-width: 640px)" />
            <source srcSet={PictureWork3768} media="(max-width: 768px)" />
            <source srcSet={PictureWork31024} media="(max-width: 1024px)" />
            <img className='w-full h-[260px] object-cover object-left' src={PictureWork3} alt="Work" />
            </picture>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Masonry