import React from 'react'
import HeroVideo from '../../assets/concretevid.mp4'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'

const Home = () => {
  return (
    <div className=''>
        <div className='w-full h-screen lg:h-[70vh]'>
        <video loop muted autoPlay className="absolute object-cover h-[70%] w-full">
            <source src={HeroVideo}  type="video/mp4" />
        </video>
        <div className="header-gradient w-full absolute h-[70%] z-0" />

        <div className="flex w-full h-full items-center justify-center">
        <div className='w-full max-w-[1200px] absolute px-12 gap-8 flex flex-col lg:grid grid-cols-2 items-center h-[70%] justify-center'>
            <h1 className='text-5xl sm:text-7xl font-normal text-white mt-8 sm:pt-0 text-center'>Concrete <br/><span className='octy font-normal text-8xl sm:text-9xl'>Solution<br/></span> Carlstad
            </h1>
            <div className="flex flex-col">
            <h2 className='uppercase font-black sm:text-3xl'>SÄKER BETONGPUMPNING för mellansverige</h2>
            <p className='text-lg pb-4'>Vi är stolta över att kunna leverera betongpumpning i hela Mellansverige. Tillsammans med kunder så hittar vi den bästa och smidigaste lösningen för ditt projekt.</p>
            <div className="flex flex-row items-center gap-4">
            <a className='group' href="/kontakt">
                <div className="flex items-center justify-center">
                                <button aria-label='Contact' className='mr-4 rounded-full w-10 h-10 flex items-center justify-center'><BsFillArrowRightCircleFill fill="#4EF4FF" className='group-hover:fill-white duration-200 w-10 h-10'/></button>
                <p className='font-black uppercase text-xl'>Kontakta oss</p>
                </div>
                </a>
                </div>
                </div>
        </div>
        </div>
    </div>



    <div className="w-full flex items-center justify-center">
    <div className="max-w-[1200px] flex flex-col md:grid sm:grid-cols-3 mb-12">
        <div className="shadow-number-box md:flex-col pb-4 lg:flex-row my-2 flex mx-4 sm:mx-0 px-4 py-2 bg-[#15152E]">
            <span className='text-[120px] leading-none burnaby pr-2'>1</span>
            <p>Concrete Solution Carlstads team har alla utbildningar som krävs för att möta Svensk Betongs riktlinjer men även för att få en förståelse och ständigt vara en del av utvecklingen.</p>
        </div>
        <div className="shadow-number-box pb-4 md:flex-col lg:flex-row flex my-2 px-4 py-2 mx-4 bg-[#15152E]">
            <span className='text-[120px] leading-none burnaby pr-2'>2</span>
            <p>Med stor erfarenhet och delaktighet i branschen ger vi dig rätt rådgivning om olika betongpumpnings tjänster. Kalkylerar och säkerhetsställer alla olika bitar i processen till att göra dig till ännu en nöjd kund. </p>
        </div>
        <div className="shadow-number-box pb-4 md:flex-col lg:flex-row my-2 flex mx-4 sm:mx-0 px-4 py-2 bg-[#15152E]">
            <span className='text-[120px] leading-none burnaby pr-2'>3</span>
            <p>Kontakta oss för konsultation för ditt projekt eller för att få en kostnadsfri offert.</p>
        </div>
    </div>
    </div>


    



    </div>
  )
}

export default Home