import React from 'react'
import { BsFacebook, BsInstagram } from 'react-icons/bs'
import Instagram from './assets/instagram.svg'
import image from './assets/6.JPG'
import LogoSVG from './assets/CSClogotyp 4.svg'
import BGLogoSVG from './assets/CSClogotyp 3.svg'
import { AiFillInstagram } from 'react-icons/ai'

const Footer = () => {
  return (
    <div className='bg-[#0F0E20] pb-12 pt-20 w-full mt-6'>
        <div className="flex flex-col md:grid grid-cols-4 gap-4 mx-4 md:items-start md:justify-items-center">
            
            <div className="md:pl-0 pl-8">
                <ul>
                    <li className='pb-4'><a title='Gå till startsidan' href="/">Hem</a></li>
                    <li className='py-4'><a title='Gå till om oss' href="/omoss">Om oss</a></li>
                    <li className='py-4'><a title='Gå till kontakt' href="/kontakt">Kontakt</a></li>
                    <li className='py-4'><a title='Gå till tjänster' href="/tjanster">Våra tjänster</a></li>
                </ul>
            </div>

            <div className="flex pl-8 relative flex-col">
                <a className='w-fit' href="/">
                    <p className='font-bold pb-2'>Carlstad Concrete Solutions</p>
                    <a href="https://www.google.com/maps/place/Concrete+Solution+Carlstad/@59.3154575,14.114038,18.82z/data=!4m15!1m8!3m7!1s0x465c9125a21dc031:0x66fbb42f7d9dcfa7!2sPr%C3%A4stgatan+28,+681+32+Kristinehamn!3b1!8m2!3d59.315439!4d14.1142188!16s%2Fg%2F11c1b7c460!3m5!1s0x465cb7f2c4bfc4b3:0x85f744f998610587!8m2!3d59.3154385!4d14.1142165!16s%2Fg%2F11f5x45kgs" target="_blank"><p className='py-2'>Prästgatan 28, 681 32 <span className='font-bold'>Kristinehamn</span></p></a>
                    <a href="tel:070-965 24 02"><p className='py-2'><span className='font-bold'>Telefon</span>: 070-965 24 02</p></a>
                    <a href="mailto:viktor@concretesolutioncarlstad.se"><p className='py-2 whitespace-nowrap'><span className='font-bold '>E-post</span>: viktor@concretesolutioncarlstad.se</p></a>
                </a>
                <div className="flex pt-2 items-center">
                    <a title='Gå till Facebook' href="https://www.facebook.com/CSCarlstad/" target="_blank">
                        <BsFacebook className='mr-4 w-10 h-10 hover:fill-[#4EF4FF] duration-200' />
                    </a>
                    <a title='Gå till Instagram' href="https://www.instagram.com/csc.ab/" target="_blank">
                        <AiFillInstagram src={Instagram} className='hover:fill-[#4EF4FF] h-[3.2rem] w-[3.2rem] duration-200' alt='instagram logotyp' />
                    </a>
                
                </div>
            </div>
            <div>

            </div>
                <img className='h-52 w-52' src={LogoSVG} alt="Logotyp" />
        </div>
        <div className="h-[2px] bg-slate-300 mt-12 mx-12">
            
        </div>
    </div>
  )
}

export default Footer