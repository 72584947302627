import React from 'react'
import Home from './Home'
import Navbar from '../../Navbar'
import Masonry from './Masonry'
import WeOffer from './WeOffer'
import About from './About'
import Service from './Service'
import Footer from '../../Footer'

const HomePage = () => {
  return (
    <div>
        
    <Navbar />
     <Home />
     <Masonry />
     <WeOffer />
     <About />
     <Service />
     <Footer />
    </div>
  )
}

export default HomePage