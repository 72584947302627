import React from 'react'
import Footer from '../../Footer'
import Navbar from '../../Navbar'
import Services from './Services'

const ServicePage = () => {
  return (
    <div>
        <Navbar />
        <Services />
        <Footer />
    </div>
  )
}

export default ServicePage