import React from 'react'
import Background from '../../assets/bglila_1.svg'

const WeOffer = () => {
  return (
    <div className='flex flex-col items-center relative justify-center pt-16 sm:pb-20'>
      <img className='absolute w-full h-[200px] sm:h-[300px] top-1 -z-10 object-cover' src={Background} alt="Penseldrag färg" />
        <h3 className='uppercase text-center text-xl sm:text-2xl'>Vi erbjuder betongpumpning</h3>
        <h2 className='uppercase text-center octy text-5xl sm:text-8xl'>I mellansverige</h2>
    </div>
  )
}

export default WeOffer