import React from 'react'
import Footer from '../../Footer'
import Navbar from '../../Navbar'
import Contact from './Contact'

const ContactPage = () => {
  return (
    <div>
        <Navbar />
        <Contact />
        <Footer />
    </div>
  )
}

export default ContactPage