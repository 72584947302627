import React from 'react'
import Footer from '../../Footer'
import Navbar from '../../Navbar'
import Work from './Work'

const ContactPage = () => {
  return (
    <div>
        <Navbar />
        <Work />
        <Footer />
    </div>
  )
}

export default ContactPage