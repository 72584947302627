import React from 'react'
import WorkPic from '../../assets/5.JPG'
import WorkPic640 from '../../assets/5-640.jpg'
import WorkPic768 from '../../assets/5-768.jpg'
import WorkPic1024 from '../../assets/5-1024.jpg'
import { AiOutlineArrowRight } from 'react-icons/ai'
import gridPicture from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture640 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture768 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture1024 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture2 from '../../assets/194384254_3192081664352792_441571331884594076_n.jpg'
import gridPicture2640 from '../../assets/194384254_3192081664352792_441571331884594076_n-640.jpg'
import gridPicture2768 from '../../assets/194384254_3192081664352792_441571331884594076_n-768.jpg'
import gridPicture21024 from '../../assets/194384254_3192081664352792_441571331884594076_n-1024.jpg'
import gridPicture3 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F.JPG'
import gridPicture3640 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-640.jpg'
import gridPicture3768 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-768.jpg'
import gridPicture31024 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-1024.jpg'
import gridPicture31280 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-1280.jpg'
import { BiDownArrowAlt } from 'react-icons/bi'
import Car from '../../assets/IMG_0228.jpg'
import Car640 from '../../assets/IMG_0228-640.jpg'
import Car768 from '../../assets/IMG_0228-768.jpg'
import Car1024 from '../../assets/IMG_0228-1024.jpg'
import { Link } from 'react-scroll'


const Services = () => {
  return (
    <div className='w-full'>
        <div className="flex justify-center items-center">
        <div className="grid md:grid-cols-1 lg:grid-cols-2 max-w-[1600px] pt-40 justify-center items-center">
            <picture>
                    <source srcSet={gridPicture640} media="(max-width: 640px)" />
                    <source srcSet={gridPicture768} media="(max-width: 768px)" />
                    <source srcSet={gridPicture1024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture} alt="2 män jobbar under bro" />
                    </picture>
            <div className="flex flex-col pt-6 md:pt-6 px-8 justify-center">
                <h4 className='text-[#4EF4FF] font-black text-3xl uppercase'>Professionell hjälp till rimliga priser</h4>
                <p className='text-lg pt-6 md:pt-6'>Tillsammans med våra kunder skapar vi otroliga resultat och därför pumpar vi mer än bara betong. Concrete solution carlstads team har alla utbildningar som krävs för att möta Svensk Betongs riktlinjer men även för att få en förståelse och ständigt vara en del av utvecklingen. Med stor erfarenhet och delaktighet i branschen ger vi dig rätt rådgivning om olika betongpumpnings tjänster. Kalkylerar och säkerhetsställer alla olika bitar i processen till att göra dig till ännu en nöjd kund. Kontakta oss för en offert och du kommer själv få se att den stämmer överens med din budget.</p>

            </div>
        </div>
        </div>

        <div className="flex justify-center pt-40 w-full items-center">
            <div className="md:grid flex flex-col grid-cols-3 px-4 gap-12 max-w-[1200px]">
                <div className="">
                <h4 className='text-[#4EF4FF] uppercase font-black text-3xl'>Betongpumpning</h4>
                <p className='text-lg'>Vi erbjuder betongpumps-jobb vid konstruktioner åt både företag och privatpersoner. Vi använder oss utav pumpningsbar betong som levereras av en betongpumpbil. Kontakta oss för ett kostnadsförslag & prislista.</p>
                <Link smooth={true} duration={500} to="betongpumpning"><p className='cursor-pointer pt-4 text-lg text-[#4EF4FF] uppercase'>Läs mer <BiDownArrowAlt className='ml-2 w-6 h-6 inline-block fill-[#4EF4FF]' /></p></Link>
                </div>
                <div className="">
                <h4 className='text-[#4EF4FF] uppercase font-black text-3xl'>konsultation</h4>
                <p className='text-lg'>Vi hjälper dig gärna med att planera ditt projekt och hitta den smidigaste, billigaste och bästa lösningen för just dig.</p>
                </div>
                <div className="">
                <h4 className='text-[#4EF4FF] uppercase font-black text-3xl'>PREFAB</h4>
                <p className='text-lg'>Vi monterar även prefab, duktiga prefabmontörer finns tillgängliga för uthyrning till era stommontage.</p>
                </div>
            </div>
        </div>

        <div className="flex flex-col items-center justify-center w-full pt-20">
            <div className="flex flex-col md:grid w-full grid-cols-3 gap-2 pt-20">
                <div className="">
                    <picture>
                    <source srcSet={gridPicture640} media="(max-width: 640px)" />
                    <source srcSet={gridPicture768} media="(max-width: 768px)" />
                    <source srcSet={gridPicture1024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture} alt="2 män jobbar under bro" />
                    </picture>
                    </div>
                <div className="">
                    <picture>
            <source srcSet={gridPicture2640} media="(max-width: 640px)" />
            <source srcSet={gridPicture2768} media="(max-width: 768px)" />
            <source srcSet={gridPicture21024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture2} alt="2 gubbar sprutar betong" />
            </picture>
            </div>
                <div className="">
                    <picture>
            <source srcSet={gridPicture3640} media="(max-width: 640px)" />
            <source srcSet={gridPicture3768} media="(max-width: 768px)" />
            <source srcSet={gridPicture31024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture3} alt="Jobb på bro" />
            </picture>
            </div>
            </div>
        </div>

        <div name="betongpumpning" className="flex justify-center pt-40 items-center">
        <div className='flex flex-col md:grid grid-cols-4 gap-4 max-w-[1200px]'>
        <div className="relative col-span-2">
          <div className="custom-gradient w-full absolute h-full" />
            <picture>
            <source srcSet={Car640} media="(max-width: 640px)" />
            <source srcSet={Car768} media="(max-width: 768px)" />
            <source srcSet={Car1024} media="(max-width: 1024px)" />
            <img className='h-[500px] object-cover object-bottom w-full' src={Car} 
            alt="Parkerad bil" />
            </picture>
            <div className="absolute px-4 py-4 bottom-0">
                <h4 className='uppercase font-bold text-[#4EF4FF] text-3xl'>Betong har lång livslängd</h4>
                <p className='text-lg'>Betong är robust och långsiktigt hållbart med lång livslängd och litet drift- och underhållsbehov med kvalitet som håller över tid. Betong brinner inte, tål fukt och möglar inte och kan återvinnas till 100%. På grund av sin vikt är betong ljuddämpande och lufttät. Med betong kan man bygga industriellt och kostnadseffektivt.</p>
            </div>
        </div>
          <div className="bg-[#282852] px-4 py-4 flex flex-col justify-between">
            <div className="">
                <h5 className='uppercase font-bold text-[#4EF4FF] text-3xl'>bra energival</h5>
                <p className='text-lg'>Betong är värmetrögt. Det innebär att materialet har hög inbyggd kapacitet att lagra överskottsvärme (gratisenergi) i ett hus och som sedan kan användas när det finns ett underskott.</p>
                </div>
               
          </div>
          <div className="bg-[#282852] px-4 py-4 flex flex-col justify-between">
            <div className="">
                  <h5 className='uppercase font-bold text-[#4EF4FF] text-3xl'>betong brinner inte</h5>
                <p className='text-lg'>Valet av byggmaterial har stor betydelse för byggnadens brandsäkerhet. Ur brandskyddssynpunkt är betong därför ett bra val. Betong brinner inte. Betong avger inte rök eller giftiga gaser. Betong smälter inte. En betongstomme behåller sin bärande kapacitet även vid brand.</p>
                </div>
          </div>
          </div>
          </div>
        
    </div>
  )
}

export default Services