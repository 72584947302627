import React from 'react'
import Car from '../../assets/IMG_0228.jpg'
import Car640 from '../../assets/IMG_0228-640.jpg'
import Car768 from '../../assets/IMG_0228-768.jpg'
import Car1024 from '../../assets/IMG_0228-1024.jpg'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'

const About = () => {
  return (
    <div className="flex items-center my-20 justify-center">
    <div className='flex flex-col md:grid grid-cols-4 gap-4 max-w-[1200px]'>
        <div className="relative col-span-2">
          <div className="custom-gradient w-full absolute h-full" />
          <picture>
            <source srcSet={Car640} media="(max-width: 640px)" />
            <source srcSet={Car768} media="(max-width: 768px)" />
            <source srcSet={Car1024} media="(max-width: 1024px)" />
            <img className='h-[500px] object-cover object-bottom w-full' src={Car} 
            alt="Car" />
            </picture>
            <div className="absolute px-4 py-4 bottom-0">
                <h4 className='uppercase font-bold text-[#4EF4FF] text-[1.2rem]'>Betong har lång livslängd</h4>
                <p className='text-lg'>Betong är robust och långsiktigt hållbart med lång livslängd och litet drift- och underhållsbehov med kvalitet som håller över tid. Betong brinner inte, tål fukt och möglar inte och kan återvinnas till 100%. På grund av sin vikt är betong ljuddämpande och lufttät. Med betong kan man bygga industriellt och kostnadseffektivt.</p>
            </div>
        </div>
          <div className="bg-[#282852] px-4 py-4 flex flex-col justify-between">
            <div className="">
                <h5 className='uppercase font-bold text-[#4EF4FF] text-[1.2rem]'>Om oss</h5>
                <p className='text-lg'>Concrete Solutions Carlstad har funnits sedan 2016 och startades av Victor & Fredrik som idag fortfarande driver företaget. Läs mer om oss!</p>
                </div>
                <div className="">
                <a href="/omoss">
                  <button aria-label='Contact' className='mr-4 rounded-full w-10 h-10 flex items-center justify-center'><BsFillArrowRightCircleFill fill="#4EF4FF" className='hover:fill-white duration-200 w-10 h-10'/></button>
                </a>
                </div>
          </div>
          <div className="bg-[#282852] px-4 py-4 flex flex-col justify-between">
            <div className="">
                  <h5 className='uppercase font-bold text-[#4EF4FF] text-[1.2rem]'>Vill du jobba hos oss?</h5>
                <p className='text-lg'>En fartfylld arbetsplats där vi alltid utvecklas och har roligt. Ett starkt team som värderar god gemenskap och att trivas på jobbet. Vi har alltid kollegor och ledare nära till hands som ger varandra de bästa förutsättningarna för att lyckas och få bäst resultat. Vill du ta reda på mer om hur det är att jobba hos oss? </p>
                </div>
                <div className="">
                <a href="/jobbahososs">
                  <button aria-label='Contact' className='mr-4 rounded-full w-10 h-10 flex items-center justify-center'><BsFillArrowRightCircleFill fill="#4EF4FF" className='hover:fill-white w-10 h-10 duration-200'/></button>
                </a>
                </div>
          </div>
          </div>
    </div>
  )
}

export default About