import Home from './components/About/Home';
import HomePage from './components/Home/HomePage';


function App() {
  return (
    <div className="App">
      
    </div>
  );
}

export default App;
