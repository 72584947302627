import React from 'react'
import Footer from '../../Footer'
import Navbar from '../../Navbar'
import Home from './Home'

const AboutPage = () => {
  return (
    <div>
        <Navbar />
        <Home />
        <Footer />
    </div>
  )
}

export default AboutPage