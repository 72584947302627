import React from 'react'
import Work from '../../assets/5.JPG'
import Work640 from '../../assets/5-640.jpg'
import Work768 from '../../assets/5-768.jpg'
import Work1024 from '../../assets/5-1024.jpg'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'
import gridPicture from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture640 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture768 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture1024 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture2 from '../../assets/194384254_3192081664352792_441571331884594076_n.jpg'
import gridPicture2640 from '../../assets/194384254_3192081664352792_441571331884594076_n-640.jpg'
import gridPicture2768 from '../../assets/194384254_3192081664352792_441571331884594076_n-768.jpg'
import gridPicture21024 from '../../assets/194384254_3192081664352792_441571331884594076_n-1024.jpg'
import gridPicture3 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F.JPG'
import gridPicture3640 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-640.jpg'
import gridPicture3768 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-768.jpg'
import gridPicture31024 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-1024.jpg'
import gridPicture31280 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-1280.jpg'
import Background from '../../assets/bglila_1.svg'


const Home = () => {
  return (
    <div className='w-full'>
        <div className="flex justify-center items-center">
        <div className="grid md:grid-cols-1 lg:grid-cols-2 max-w-[1600px] pt-40 justify-center items-center">
            <div className="flex flex-col pt-6 pb-6 md:pt-6 px-8 justify-center bg-[#15152E]">
                <h4 className='text-[#4EF4FF] font-black text-3xl uppercase'>Om concrete Solutions Carlstad</h4>
                <p className='text-lg'>Concrete Solution Carlstad AB grundades 2016 i Karlstad av Victor Haglund och Fredrik Göransson, då vi såg ett behov av släpvagnspumpar i vårt närområde. Med en lång erfarenhet av betongarbeten av olika slag så föll det sig naturligt för oss att börja pumpa betong. I dagsläget så har vi två Putzmeister P715 för uthyrning till era projekt. Med ett professionellt och serviceinriktat tänk så har vi idag hjälpt ca 150 företag och privatpersoner med betongpumpning, därav en stor del är återkommande. Långt eller kort, lite eller mycket, vi fixar betongen dit du vill utan problem.</p>
            </div>
            <picture>
            <source srcSet={Work640} media="(max-width: 640px)" />
            <source srcSet={Work768} media="(max-width: 768px)" />
            <source srcSet={Work1024} media="(max-width: 1024px)" />
            <img src={Work} className="" alt="4 jobbande män" />
            </picture>
        </div>
        </div>

        <div className="flex flex-col relative items-center justify-center w-full pt-40">
      <img className='absolute w-full h-[400px] sm:h-[300px] top-28 md:top-28 rotate-180 -z-10 object-cover' src={Background} alt="Splashfärg" />

            <h2 className='uppercase px-4 text-center text-4xl md:text-5xl'>Vi erbjuder grym<br/> 
            <span className='octy text-6xl md:text-7xl'>service och kompetens!</span></h2>
            <div className="flex pt-8 px-4 items-center">
            <a className='group' href="/tjanster">
                <div className="flex items-center justify-center">
                <button aria-label='Contact' className=' mr-4 rounded-full w-10 h-10 flex items-center justify-center'><BsFillArrowRightCircleFill fill="#4EF4FF" className='group-hover:fill-white duration-200 w-10 h-10'/></button>
            <p className='font-black uppercase text-xl'>Ta reda på mer om våra tjänster</p>
            </div>
            </a>
            </div>
            <div className="flex flex-col sm:grid grid-cols-3 gap-2 pt-8">
                <div className="">
                    <picture>
                    <source srcSet={gridPicture640} media="(max-width: 640px)" />
                    <source srcSet={gridPicture768} media="(max-width: 768px)" />
                    <source srcSet={gridPicture1024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture} alt="2 män jobbar under bro" />
                    </picture>
                </div>
                <div className="">
                    <picture>
            <source srcSet={gridPicture2640} media="(max-width: 640px)" />
            <source srcSet={gridPicture2768} media="(max-width: 768px)" />
            <source srcSet={gridPicture21024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture2} alt="2 män sprutar betong" />
            </picture>
            </div>
                <div className="">
                    <picture>
            <source srcSet={gridPicture3640} media="(max-width: 640px)" />
            <source srcSet={gridPicture3768} media="(max-width: 768px)" />
            <source srcSet={gridPicture31024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture3} alt="2 bilar på en bro" />
            </picture>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Home