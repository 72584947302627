import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './components/Home/HomePage';
import AboutPage from './components/About/AboutPage';
import ContactPage from './components/Contact/ContactPage';
import WorkPage from './components/Work/WorkPage';
import ServicePage from './components/Services/ServicePage';



const landingPage = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/omoss",
    element: <AboutPage />,
  },
  {
    path: "/kontakt",
    element: <ContactPage />,
  },
  {
    path: "/jobbahososs",
    element: <WorkPage />,
  },
  {
    path: "/tjanster",
    element: <ServicePage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={landingPage} />
  </React.StrictMode>
);
