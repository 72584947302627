import React from 'react'
import WorkPic640 from '../../assets/20201125_101253-640.jpg'
import WorkPic768 from '../../assets/20201125_101253-768.jpg'
import WorkPic1024 from '../../assets/20201125_101253-1024.jpg'
import WorkPic1280 from '../../assets/20201125_101253-1280.jpg'
import WorkPic from '../../assets/20201125_101253.jpg'
import { AiOutlineArrowRight } from 'react-icons/ai'
import gridPicture from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture640 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture768 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture1024 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture2 from '../../assets/194384254_3192081664352792_441571331884594076_n.jpg'
import gridPicture2640 from '../../assets/194384254_3192081664352792_441571331884594076_n-640.jpg'
import gridPicture2768 from '../../assets/194384254_3192081664352792_441571331884594076_n-768.jpg'
import gridPicture21024 from '../../assets/194384254_3192081664352792_441571331884594076_n-1024.jpg'
import gridPicture3 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F.JPG'
import gridPicture3640 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-640.jpg'
import gridPicture3768 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-768.jpg'
import gridPicture31024 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-1024.jpg'
import gridPicture31280 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-1280.jpg'


const Work = () => {
  return (
    <div className='w-full'>
        <div className="flex justify-center items-center">
        <div className="grid md:grid-cols-1 lg:grid-cols-2 max-w-[1600px] pt-40 justify-center items-center">
            <picture>
                    <source srcSet={WorkPic640} media="(max-width: 640px)" />
                    <source srcSet={WorkPic768} media="(max-width: 768px)" />
                    <source srcSet={WorkPic1024} media="(max-width: 1024px)" />
                    <img src={WorkPic} className="w-full h-auto" alt="4 jobbande män" />
                    </picture>
            <div className="flex flex-col pt-6 md:pt-6 px-8 justify-center">
                <h4 className='text-[#4EF4FF] font-black text-3xl uppercase'>Vi är ofta på jakt efter fler arbetskollegor med rätt kompetens! </h4>
                <p className='font-bold text-lg pt-6 md:pt-6'>Vi söker pumpmaskinist.</p>
                <p className='text-lg pt-6 md:pt-6'>Vi lägger stor vikt vid att du håller tider, är ansvarsfull och tar god hand om den utrustning som du ansvarar för. Det är nämligen du som kommer ansvara för underhåll, rengöring och bokning av service/reparation. Vi erbjuder ett varierande och roligt arbete med grymma kollegor samt: <br />- Konkurrenskraftig lön + betald upplärningstid <br />- All utbildning och utrustning du behöver för att utföra arbetet</p>
                <p className='font-bold text-lg'>Vi söker även prefabmontörer och svetsare.</p>

                <p className='text-lg'>Är du intresserad av att jobba hos oss? Kontakta oss med en presentation om dig själv och vad du kan erbjuda oss till: <a className='text-[#4EF4FF]' href="mailto:viktor@concretesolutioncarlstad.se">viktor@concretesolutioncarlstad.se</a></p>

            </div>
        </div>
        </div>

        <div className="flex flex-col items-center justify-center w-full pt-20">
            <div className="flex flex-col sm:grid grid-cols-3 gap-2 pt-20">
                <div className="">
                    <picture>
                    <source srcSet={gridPicture640} media="(max-width: 640px)" />
                    <source srcSet={gridPicture768} media="(max-width: 768px)" />
                    <source srcSet={gridPicture1024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture} alt="2 män jobbar under bro" />
                    </picture>
                    </div>
                <div className="">
                    <picture>
            <source srcSet={gridPicture2640} media="(max-width: 640px)" />
            <source srcSet={gridPicture2768} media="(max-width: 768px)" />
            <source srcSet={gridPicture21024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture2} alt="2 gubbar sprutar betong" />
            </picture>
            </div>
                <div className="">
                    <picture>
            <source srcSet={gridPicture3640} media="(max-width: 640px)" />
            <source srcSet={gridPicture3768} media="(max-width: 768px)" />
            <source srcSet={gridPicture31024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture3} alt="Jobb på bro" />
            </picture>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Work