import React from 'react'
import Background from '../../assets/bglila_1.svg'
import Work from '../../assets/5.JPG'
import { AiOutlineArrowRight } from 'react-icons/ai'
import gridPicture from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture640 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture768 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture1024 from '../../assets/5DE1EC74-9BA2-4DF6-84BB-A4444FE94277.JPG'
import gridPicture2 from '../../assets/194384254_3192081664352792_441571331884594076_n.jpg'
import gridPicture2640 from '../../assets/194384254_3192081664352792_441571331884594076_n-640.jpg'
import gridPicture2768 from '../../assets/194384254_3192081664352792_441571331884594076_n-768.jpg'
import gridPicture21024 from '../../assets/194384254_3192081664352792_441571331884594076_n-1024.jpg'
import gridPicture3 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F.JPG'
import gridPicture3640 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-640.jpg'
import gridPicture3768 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-768.jpg'
import gridPicture31024 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-1024.jpg'
import gridPicture31280 from '../../assets/EBB0DBA4-B800-49F4-9D3A-E9D84699166F-1280.jpg'
import { FiMail } from 'react-icons/fi'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'




const Contact = () => {
  return (
    <div className='w-full'>
        <div className="flex justify-center items-center">
        <div className="grid md:grid-cols-1 lg:grid-cols-2 max-w-[1600px] pt-40 justify-center items-center">
            <img src={Work} className="" alt="4 jobbande män" />
            <div className="flex flex-col sm:max-w-lg pt-6 md:pt-6 px-8 md:px-8 justify-center">
                <h4 className='text-[#4EF4FF] font-black text-3xl uppercase'>Kontakt</h4>
                <p className='text-lg'>För kostnadsförslag och prislista, kontakta oss via mejl eller telefon.</p>
                <div className="flex flex-col md:shadow-number-box pt-6 md:pt-6 md:py-8 md:justify-center md:bg-[#15152E]">
                    <h5 className='text-xl font-bold uppercase pt-6 md:pt-6'>Kontaktuppgifter</h5>
                    <a className='py-2 group' href="tel:070-965 24 02"><p className='text-lg group-hover:text-[#4EF4FF] duration-200'><i class="fa-solid fa-phone group-hover:text-[#4EF4FF] duration-200"></i> 070-965 24 02</p></a>
                    <a className='pb-2 group' href="tel:070-965 24 02"><p className='text-lg group-hover:text-[#4EF4FF] duration-200'><i class="fa-solid fa-phone group-hover:text-[#4EF4FF] duration-200"></i> 073-849 51 45</p></a>
                    <a className='pb-2 group whitespace-nowrap' href="mailto:fredrik@concretesolutioncarlstad.se"><p className='text-lg group-hover:text-[#4EF4FF] duration-200'><i class="fa-solid fa-envelope group-hover:text-[#4EF4FF] duration-200"></i> fredrik@concretesolutioncarlstad.se</p></a>
                    <a className='whitespace-nowrap group' href="mailto:victor@concretesolutioncarlstad.se"><p className='text-lg group-hover:text-[#4EF4FF] duration-200'><i class="fa-solid fa-envelope group-hover:text-[#4EF4FF] duration-200"></i> victor@concretesolutioncarlstad.se</p></a>
                </div>

            </div>
        </div>
        </div>

        <div className="flex flex-col relative mt-20 items-center justify-center w-full pt-20">
            <img className='absolute w-full h-[350px] sm:h-[250px] top-16 sm:top-9 rotate-180 -z-10 object-cover' src={Background} alt="Penseldrag färg" />
            <h2 className='uppercase px-4 text-center text-4xl md:text-5xl'>Vi erbjuder grym <br /> <span className='octy text-6xl md:text-7xl'>service och kompetens!</span></h2>
            <div className="flex items-center px-4 pt-4">
            <a className='group' href="/tjanster">
                <div className="flex items-center justify-center">
                <button aria-label='Contact' className='mr-4 rounded-full w-10 h-10 flex items-center justify-center'><BsFillArrowRightCircleFill fill="#4EF4FF" className='group-hover:fill-white duration-200 w-10 h-10'/></button>
            <p className='font-black uppercase text-lg md:text-xl'>Ta reda på mer om våra tjänster</p>
                </div>

            </a>
            </div>
            <div className="flex flex-col sm:grid grid-cols-3 gap-2 pt-8">
                <div className="">
                    <picture>
                    <source srcSet={gridPicture640} media="(max-width: 640px)" />
                    <source srcSet={gridPicture768} media="(max-width: 768px)" />
                    <source srcSet={gridPicture1024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture} alt="2 män jobbar under bro" />
                    </picture>
                </div>
                <div className="">
                    <picture>
            <source srcSet={gridPicture2640} media="(max-width: 640px)" />
            <source srcSet={gridPicture2768} media="(max-width: 768px)" />
            <source srcSet={gridPicture21024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture2} alt="2 män sprutar betong" />
            </picture>
            </div>
                <div className="">
                    <picture>
            <source srcSet={gridPicture3640} media="(max-width: 640px)" />
            <source srcSet={gridPicture3768} media="(max-width: 768px)" />
            <source srcSet={gridPicture31024} media="(max-width: 1024px)" />
                    <img className='h-[368px] w-full object-cover' src={gridPicture3} alt="2 bilar på en bro" />
            </picture>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Contact